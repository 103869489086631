import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import api from '../../services/api';
import { getUserType } from "../../services/auth";

//Componentes
import Header from '../../components/header';
import TitlePage from '../../components/title-page';
 import Search from '../../components/search';
// import Dropdown from '../../components/dropdown';

export default class releases extends Component {
	state = {
		search: "",
		page: 1,
		currentPos: 1,
		notAdmin: false,
	}

	componentDidMount() {
		//if page reloads without close any modal
		document.querySelector('body').classList.remove("no-scroll");

		if(getUserType() !== "admin") {
			this.setState({notAdmin: true});
		} else {
			const page = this.props.match.params.page;
			this.countPublishers(page, this.state.view);
		}
	}

	countPublishers = async(page, view) => {
		//makes publishers count
		//from the counting it does the division of pages
		//uploads publishers for the current page

		try {
			const countResponse = await api.get(`publishers/count`);
			this.setState({
				totalPublishers: countResponse.data.count,
				totalPages: Math.ceil(countResponse.data.count / view)
			});

			if(page > Math.ceil(countResponse.data.count / view)) {
				page = Math.ceil(countResponse.data.count / view);
				this.props.history.push(`/list-publishers/${page}`);
			}

			this.loadApiPublishers(view, page, this.state.totalPublishers, this.state.order);
		} catch(error) {
			console.log(error);
		}
	}

	render() {
		// redirect  if not admin user
		if(this.state.notAdmin) {
			return <Redirect to='/projects' />
		}
		return (
			<div data-page="list-publishers">
				<Header />
				<TitlePage title="Official Releases"/>
				<div className="container-fluid" data-component="page-list-content">
					<div className="container">
						<div data-component="content-list-header">
						<div className="row">
                            <h2>Seat7 Portal October Update - 2024 Release</h2>
						</div>
							<h3>Seat7 Portal 4.3.2 <span className='badge'>Latest</span>&nbsp;<span className='badge'>Build 102324.</span></h3>
							<h5>Oct 23, 2023</h5>
							<br />
							<div className="row">
								<ul>
								<li>Compatibility improvements in contact and contact list imports</li>
								<li>Important fixes within the API modifying endpoints</li>
								<li>The contact search tool is enabled in project submission and contact search modals</li>
								</ul>
							</div>
							<br/>
						<div className="row">
                            <h2>Seat7 Portal September Update</h2>
						</div>
							<h3>Seat7 Portal 4.3.1</h3>
							<h5>Sep 25, 2023</h5>
							<br />
							<div className="row">
								<ul>
								<li>There is a new name in the seat7 portal, Now publishers become contacts and clients become providers</li>
								<li>The administration of contacts for providers (previously clients) is eliminated</li>
								<li>Bug Fixes</li>
								<li>The implementation of contact import is improved (Now it allows updating the record of each user and adding it if it does not exist in the current list)</li>
								</ul>
							</div>
							<br/>

						<div className="row">
                            <h2>Seat7 Portal May Update</h2>
							</div>
							<h3>Seat7 Portal 4.3.0</h3>
							<h5>May 15, 2023</h5>
							<br />
							<div className="row">
								<ul>
								<li>Update of New administrator resources</li>
								<li>Feature of importing a list of publishers from a CSV file</li>
								<li>Bug Fixes</li>
								<li>Support for active components in JSON format</li>
								<li>Scalable information redirection from our API.</li>
								</ul>
							</div>
							<br/>

							<div className="row">
                                <h2>Seat7 Portal July Update</h2>
							</div>
							<h3>Seat7 Portal 4.23.2</h3>
							<h5>August 03, 2023</h5>
							<br />
							<div className="row">
								<ul>
									<li>Important changes in the administration of the email delivery center</li>
									<li>Changes to the delivery method of email addresses on services.api.seat7entertainment.com</li>
								</ul>
							</div>
							<br/>
							<h5>August 25, 2023</h5>
							<br />
							<div className="row">
								<ul>
									<li>Incorporation of alerts in the image upload sections of the projects</li>
								</ul>
							</div>
							<br />
							<h3>Seat7 Portal 4.23.1</h3>
							<h5>July 19, 2023</h5>
							<br />
							<div className="row">
								<ul>
									<li>Image rendering improvements</li>
									<li>Direct Access to the Pink Genie Portal</li>
									<li>Bug Fixes</li>
									<li>Stability in Supported Browsers</li>
									<li>Modal notifications when updating files</li>
								</ul>
							</div>
							<br />
							<br />
							<div className="row">
                                <h2>Seat7 Portal January Update - 2023 Release</h2>
							</div>
							<h3>Seat7 Portal 4.23.0</h3>
							<h5>January 06, 2023</h5>
							<br />
							<div className="row">
								<ul>
									<li>Bug Fixes</li>
									<li>Stability in Supported Browsers</li>
									<li>Modal notifications when updating files</li>
									<li>Details Button on Publisher Modal</li>
									<li>Search Bar</li>
								</ul>
							</div>
							<br />
							<br />
							<div className="row">
                                <h2>Seat7 Portal November Update</h2>
							</div>
							<h3>Seat7 Portal 4.22.1</h3>
							<h5>November 23, 2022</h5>
							<br />
							<div className="row">
								<ul>
									<li>Api Security Patch - Delivery Method</li>
									<li>Bug Fixes on Delivery Methods</li>
								</ul>
							</div>
							<br />
							<h3>Seat7 Portal 4.22.0</h3>
							<h5>November 01, 2022</h5>
							<br />
							<div className="row">
								<ul>
									<li>Api Security Patch</li>
									<li>Bug Fixes</li>
									<li>Stability in Supported Browsers</li>
									<li>Iconography</li>
								</ul>
							</div>
							<br />
							<br />
							<div className="row">
                                <h2>Seat7 Portal September Update</h2>
							</div>
							<br />
							<h3>Seat7 Portal 4.21.0</h3>
							<h5>September 19, 2022</h5>
							<br />
							<div className="row">
								<ul>
									<li>Responsive Design</li>
									<li>Bug Fixes</li>
									<li>Stability in Supported Browsers</li>
									<li>Fixed mute error handling for NodeJS</li>
									<li>Fixed email templates</li>
								</ul>
							</div>
							<br />
							<br />
							<center>
								<div>
									<img src='https://seat7-emails.s3.amazonaws.com/Seat7_02.png' width="50" height="50"></img>
									<br />
								<h5>©2024. Seat7 Entertainment LLC.</h5>
								</div>
							</center>
						</div>
					</div>
				</div>
			</div>
		)
	};
}