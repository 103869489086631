import React from 'react';
import { BrowserRouter, Route, Switch, Redirect }  from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import Login from './pages/login';
import ForgetPassword from './pages/forget-password';
import PasswordRecovery from './pages/password-recovery';
import ResetPassword from './pages/reset-password';
import Register from './pages/register';
import EvaluationRegister from './pages/evaluation-register';
import Main from './pages/main';
import Project from './pages/project';
import ProjectEdit from './pages/project-edit';
import CreateProject from './pages/create-project';
import ClearCache from './pages/clear-cache';
import Contacts from './pages/contacts';
import EditProject from './pages/edit-project';
import LandingPage from './pages/landing-page';
import LandingPagePreview from './pages/landing-page-preview';
import ListUsers from './pages/list-users';
import ListContacts from './pages/list-contacts';
import releases from './pages/releases';
import User from './pages/user';
import ListPublishers from './pages/list-publishers';
import Publisher from './pages/publisher';
import ImportPublisher from './pages/import-publisher';
import PublisherCC from './pages/publisher-cc';
import ShippingManagement from './pages/shipping-management';
import ConfidentialityNotAccepted from './pages/confidentiality-not-accepted';
import FollowUp from './pages/follow-up';
import EmailScheduler from './pages/email-scheduler';
import Management from 'pages/releases';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<PrivateRoute exact path="/index.html" component={Main} />
			<Route exact path="/" component={Login} />
			{/*<Route path="/institutional-page" component={InstititutionalPage}/>*/}
			<Route path="/login" component={Login} />
			<Route path="/forget-password" component={ForgetPassword} />
			<Route path="/reset-password/:token_id" component={ResetPassword} />
			<Route path="/register" component={Register} />
			<Route path="/evaluation-register" component={EvaluationRegister} />
			<Route path="/landing-page/:project_id/:token_id" component={LandingPage} />
			<Route path="/clear-cache/" component={ClearCache} />
			<PrivateRoute path="/projects" component={Main} />
			<PrivateRoute path="/project/:company_id/:project_id" component={Project} />
			<PrivateRoute path="/project-edit/:company_id/:project_id" component={ProjectEdit} />
			<PrivateRoute path="/create-project/:company_id/:project_id" component={CreateProject} />
			<PrivateRoute path="/contacts/:page" component={Contacts} />
			<PrivateRoute path="/edit-project/:company_id/:project_id" component={EditProject} />
			<PrivateRoute path="/list-users/:page" component={ListUsers} />
			<PrivateRoute path="/list-contacts/:page" component={ListContacts} />
			<PrivateRoute path="/releases/:page" component={releases} />
			<PrivateRoute path="/user/:id" component={User} />
			<PrivateRoute path="/list-publishers/:page" component={ListPublishers} />
			<PrivateRoute path="/publisher/:id" component={Publisher} />
			<PrivateRoute path="/import-publisher/:id" component={ImportPublisher} />
			<PrivateRoute path="/publisher-cc/:id" component={PublisherCC} />
			<PrivateRoute path="/landing-page-preview/:company_id/:project_id" component={LandingPagePreview} />
			<PrivateRoute path="/shipping-management/:company_id/:project_id" component={ShippingManagement} />
			<PrivateRoute path="/follow-up/:company_id/:project_id" component={FollowUp} />
			<PrivateRoute path="/email-scheduler/:company_id/:project_id" component={EmailScheduler} />
			<Route path="/without-permission" component={ConfidentialityNotAccepted} />
			<Route path="*" component={() => <h1>Page not founds</h1>} />
		</Switch>
	</BrowserRouter>
)

export default Routes;