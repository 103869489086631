import React, { Component } from 'react';
import { Markup } from 'interweave';

export default class EmailPreview extends Component {
	componentDidMount() {
	}

	render() {
		return (
			<div data-component="email-preview">
				<table align="center" border="0" cellpadding="0" cellspacing="0" width="769">
					{/* <tr>
						<td align="center" bgcolor="#0056a7" className="thead">
							<div target="_blank">
								<img src="https://seat7-emails.s3.amazonaws.com/logo-white.png" alt="Seat 7 Entertainment" width="120" height="170" />
							</div>
						</td>
					</tr> */}
					<tr>
						<td align="left" bgcolor="#ffffff" className="tbody" >
							<p className="email-text">
								{ this.props.message !== "" &&
									<Markup content={this.props.message} />
								}
								{ this.props.message === "" &&
									"This email is without a message"
								}
							</p>
							<br />
							<br />
							<div align="left">
							<div target="_blank" className="btn-link">View Details</div>
							</div>
							<br />
							<br />
							<p className="email-text">
							Of course, I am happy to answer any question you may have.<br /><br />
							Please let me know your thoughts.<br /><br />
							With Regards,<br /><br />
							Carlos<br />
							</p>
						</td>
					</tr>
				</table>
			</div>
		)
	};
}