import React, { useState } from 'react';
import api from '../../services/api';
import Header from '../../components/header';
import TitlePage from '../../components/title-page';

export default function ImportPublisher() {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [uploadStatus, setUploadStatus] = useState("");
    const [isFileImported, setIsFileImported] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Estado para el porcentaje de carga
    const [loading, setLoading] = useState(false); // Estado para el loader
    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const csvFileToArray = (string) => {
        var string2 = string.replaceAll('\r', '');

        const csvHeader = string2.slice(0, string2.indexOf("\n")).split(",");
        const csvRows = string2.slice(string2.indexOf("\n") + 1).split("\n");

        const newArray = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });
        var newArray2 = []
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i]["Publisher Name"] !== '')
                newArray2.push(newArray[i])
        }

        setArray(newArray2);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
    
        if (isFileImported) {
            try {
                setLoading(true); // Mostrar loader
                setUploadStatus("Uploading data...");
                let progress = 0;
    
                // Agrupar registros por Publisher Name
                const groupedPublishers = array.reduce((acc, item) => {
                    const { "Publisher Name": name } = item;
                    if (!acc[name]) {
                        acc[name] = [];
                    }
                    acc[name].push(item);
                    return acc;
                }, {});
    
                const totalItems = Object.keys(groupedPublishers).length;
    
                for (const [index, [name, items]] of Object.entries(Object.entries(groupedPublishers))) {
                    const firstItem = items[0];
                    const {
                        "Tags": tags
                    } = firstItem;
    
                    let publisherResponse;
    
                    if (name === undefined || name === "")
                        continue;
    
                    const existingPublisher = await api.get(`publishers?filter[where][name]=${name}`);
    
                    if (existingPublisher == null || existingPublisher.data == null) {
                        // Manejar el error adecuadamente
                        continue;
                    }
    
                    // Si el publisher no existe, lo creamos con solo name y tags
                    if (existingPublisher.data.length === 0) {
                        publisherResponse = await api.post("publishers", {
                            name,
                            tags
                        });
                    } else {
                        publisherResponse = { data: existingPublisher.data[0] };
                        // Actualizar el publisher si los tags son diferentes
                        if (existingPublisher.data[0].tags !== tags) {
                            await api.put(`publishers/${existingPublisher.data[0].id}`, {
                                tags
                            });
                        }
                    }
    
                    const publisher_id = publisherResponse.data.id;
    
                    // Subir los empleados (todos los registros)
                    for (let i = 0; i < items.length; i++) {
                        const {
                            "Contact Name": e_name,
                            "Email": e_email,
                            "Phone Number": e_phone,
                            "Contact Last Name": e_last_name
                        } = items[i];
    
                        if (e_last_name === undefined) e_last_name = " ";
    
                        // Verificar si el empleado ya existe para este publisher
                        const existingEmployee = await api.get(
                            `publishers/${publisher_id}/employees?filter[where][name]=${e_name}&filter[where][last_name]=${e_last_name}&filter[where][email]=${e_email}`
                        );
    
                        if (existingEmployee.data.length > 0) {
                            // Actualizar si hay cambios en los datos
                            const employee = existingEmployee.data[0];
                            if (
                                employee.email !== e_email ||
                                employee.phone !== e_phone
                            ) {
                                await api.put(`publishers/${publisher_id}/employees/${employee.id}`, {
                                    email: e_email,
                                    phone: e_phone
                                });
                            }
                        } else {
                            // Si no existe el empleado, lo creamos
                            await api.post(`publishers/${publisher_id}/employees`, {
                                name: e_name,
                                last_name: e_last_name,
                                email: e_email,
                                office_post: [],
                                phone: e_phone,
                                setup: "TO"
                            });
                        }
                    }
    
                    progress = Math.floor((index / totalItems) * 100);
                    setUploadProgress(progress);
                }
    
                setUploadProgress(100);
                setUploadStatus("Data uploaded successfully");
                setLoading(false);
                window.location.href = '../list-publishers/1';
            } catch (error) {
                console.error("Error uploading data:", error);
                setUploadStatus("Some Data Could Not be Loaded Correctly");
                setLoading(false);
            }
        } else {
            if (file) {
                fileReader.onload = function (event) {
                    const text = event.target.result;
                    csvFileToArray(text);
                    setIsFileImported(true);
                    setUploadStatus("Data loaded. Click 'Import Publishers' to upload.");
                };
                fileReader.readAsText(file);
            }
        }
    };
    
    


    return (
        <div data-page="import-publisher">
            <Header />
            <TitlePage title="Import Contacts (Publishers)" />
            <div className="container-fluid" data-component="content-importpublisher">
                <div className="container gutter">
                    <h2>Import Contacts</h2>
                    <br />
                    <a href='../list-publishers/1' className="btn blue-light btn-save">
                        Back to Contacts
                    </a>
                    &nbsp;
                    <a href='../publisher/0' className="btn blue-light btn-save">
                        New Contact
                    </a>
                    <br />
                    <br />
                    <div className="notice" id="myNotice">
                    
                    <strong>Important!</strong>
                    <br />
                    <br />
                        <div>
                        1. You must upload a csv file with the default template. The system allows csv configurations of different formats, but the data must be structured so as not to affect our records
                        <br />
                        
                        2. When uploading the data, you will previously see a table with the records. Once validated, press upload data
                        <br />
                        
                        3. The import system allows you to identify new records and add them without duplicating information. Even if any of them are edited in the file, the information of the modified record will also be edited in the system
                        <br />
                        
                        4. The system is <strong>LIMITED to Deleting Records</strong> , so if a record is deleted in the csv file, it will remain in the system.
                        <br />
                        
                        5. If you need to delete a record, you must use the system to delete it.
                        </div>
                    </div>
                    <br />
                    
                    <form onSubmit={handleOnSubmit}>
                    <h3>Select a CSV file to import contacts</h3>
                    <br />
                        <input
                            className='btn'
                            type={"file"}
                            id={"csvFileInput"}
                            accept={".csv"}
                            onChange={handleOnChange}
                        />
                        <br />
                        <br />
                        <button type="submit" className="btn blue-light btn-save">
                            {isFileImported ? "Import Publishers" : "Load Data"}
                        </button>
                        <br />
                    </form>
                    <br />
                    <br />
                    {uploadStatus && <p>{uploadStatus}</p>}
                    {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>} 
                    {loading && ( 
                        <div class="loader-wrapper">   
                            <div class="loader-container">                     
                            </div>
                        </div>
                    )}

                    {array.length > 0 && (
                        <div className='container-table'>
                            <div className='responsive-table'>
                                <br />
                                <center>
                                    <table className='responsive-table'>
                                        <thead>
                                            <tr className='table-header col col-1'>
                                                {Object.keys(array[0]).map((key) => (
                                                    <th key={key}>{key} | &nbsp;</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {array.map((item, index) => (
                                                <tr className='table-row' key={index}>
                                                    {Object.values(item).map((val, idx) => (
                                                        <td className='col col-1' key={idx}>{val}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </center>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
